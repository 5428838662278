import React from 'react';
import { object, bool } from 'prop-types';
import { FormattedMessage } from '@kyruus/intl';
import { Container, Heading } from './styles';
import LoadingSkeleton from './loading-skeleton';
import AdditionalInfoIcon from './AdditionalInfoIcon';
import { messages } from './messages';

export default function AdditionalInfo({ loading, locationData = {} }) {
  const { accessibility = [] } = locationData;
  if (loading) {
    return <LoadingSkeleton />;
  }
  return (
    <React.Fragment>
      {locationData && accessibility.length ? (
        <Container data-testid="LocationAdditionalInfo">
          <Heading>
            <FormattedMessage {...messages.additionalInfo} />
          </Heading>
          {accessibility.map((item) => (
            <AdditionalInfoIcon
              key={item.name}
              type={item['_entity_id']}
              name={item.name}
            />
          ))}
        </Container>
      ) : null}
    </React.Fragment>
  );
}

const propTypes = {
  loading: bool.isRequired,
  locationData: object
};

AdditionalInfo.propTypes = propTypes;
