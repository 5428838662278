import { defineMessages } from '@kyruus/intl';

export const messages = defineMessages({
  infoListBoxInsurances: {
    id: 'infoListBox.insurances',
    description: 'InfoListBox insurance title',
    defaultMessage: 'Insurance Accepted'
  },
  infoListBoxInsurancesSearch: {
    id: 'infoListBox.insurances.search',
    description: 'InfoListBox insurance search title',
    defaultMessage: 'Search plans'
  },
  infoListBoxServices: {
    id: 'infoListBox.services',
    description: 'InfoListBox services title',
    defaultMessage: 'Services'
  },
  infoListBoxServicesSearch: {
    id: 'infoListBox.services.search',
    description: 'InfoListBox services search title',
    defaultMessage: 'Search services'
  },
  infoListBoxSpecialties: {
    id: 'infoListBox.specialties',
    description: 'InfoListBox specialties title',
    defaultMessage: 'Specialties'
  },
  infoListBoxSpecialtiesSearch: {
    id: 'infoListBox.specialties.search',
    description: 'InfoListBox specialties search title',
    defaultMessage: 'Search specialties'
  }
});
