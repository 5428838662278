import React from 'react';
import { ProfileRow, ProfileContainer } from './styles';
import { getEmail, getFax, getPhone, getWebsiteUrl } from './utils';
import { array, func } from 'prop-types';
import { messages } from './messages';
import { FormattedMessage } from '@kyruus/intl';

export default function Profile({ contacts, urls, log }) {
  const websiteUrl = getWebsiteUrl(urls);
  return (
    <ProfileContainer>
      {getPhone(contacts) && (
        <ProfileRow>
          <label>
            <FormattedMessage {...messages.labelPhone} />
          </label>
          <span>
            <a
              data-testid="PhoneLink"
              href={`tel:${getPhone(contacts)}`}
              onClick={() => {
                log('user_action.location_page.phone_link_click');
              }}
            >
              {getPhone(contacts)}
            </a>
          </span>
        </ProfileRow>
      )}
      {websiteUrl?.href && (
        <ProfileRow>
          <label>
            <FormattedMessage {...messages.labelWebsite} />
          </label>
          <span>
            <a
              data-testid="WebsiteLink"
              rel="noopener noreferrer"
              target="_blank"
              href={websiteUrl.href}
              onClick={() =>
                log('user_action.location_page.website_link_click')
              }
            >
              {websiteUrl.display}
            </a>
          </span>
        </ProfileRow>
      )}
      {getEmail(contacts) && (
        <ProfileRow>
          <label>
            <FormattedMessage {...messages.labelEmail} />
          </label>
          <span>
            <a
              data-testid="EmailLink"
              href={`mailto:${getEmail(contacts)}`}
              onClick={() => {
                log('user_action.location_page.email_link_click');
              }}
            >
              {getEmail(contacts)}
            </a>
          </span>
        </ProfileRow>
      )}
      {getFax(contacts) && (
        <ProfileRow>
          <label>
            <FormattedMessage {...messages.labelFax} />
          </label>
          <span>{getFax(contacts)}</span>
        </ProfileRow>
      )}
    </ProfileContainer>
  );
}

const propTypes = {
  contacts: array,
  urls: array,
  log: func.isRequired
};

const defaultProps = {
  contacts: [],
  urls: []
};

Profile.propTypes = propTypes;
Profile.defaultProps = defaultProps;
